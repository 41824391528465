<script lang="ts" setup>
import type { News } from '~~/layers/api/models/Posts'
import {formatTimestampAsDate} from "../../utils/formatter"

interface Props {
    item: News;
}

const props = defineProps<Props>()

const previewTextLength = 80

const previewContent = computed(() => {
  const preview = props.item.preview

  if (!preview) {
    return ''
  }

  if (preview.length > previewTextLength) {
    return preview.slice(0, previewTextLength) + '...'
  }

  return preview
})
</script>

<template>
    <figure
        class="snap-start shrink-0 w-40 lg:w-48 xl:w-56 2xl:w-72 flex flex-col gap-2"
    >
        <NuxtImg
            v-if="item.image"
            :src="item.image"
            :alt="item.title"
            sizes="160px sm:184px lg:228px xl:268px"
            class="aspect-[16/10] lg:aspect-[18/14] xl:aspect-[22/14] 2xl:aspect-[26/14] rounded-xl object-cover"
            loading="lazy"
        />

        <TextSubtitle bold>{{ item.title }}</TextSubtitle>

        <TextBody class="block">
            {{ previewContent }}
        </TextBody>

        <TextBody class="text-uk-gray block">
            {{ formatTimestampAsDate(item.created_at) }}
        </TextBody>
    </figure>
</template>

<style scoped></style>
